<template>
  <NuxtLayout name="default">
    <MoneyBackCard />
    <WinEverCard />
    <NuxtLayout
      name="teasers"
      :no-results="!query.loading && teasers.length <= 0"
      @refresh="query.refetch"
    >
      <Component
        :is="component"
        v-for="({ component, id, props }, index) in teasers.slice(0, 2)"
        :key="id"
        v-bind="props"
        :fetchpriority="index < priorityFetchAmount ? 'high' : 'low'"
        @click="handleTeaserClick(id)"
      />

      <TrustTeaserCarousel class="carousel" :slides="trustTeaserWinners">
        <template #headline>
          <h2 class="text-h5">
            {{ trustTeaserCarousels?.winnersTitle }}
          </h2>
        </template>
      </TrustTeaserCarousel>

      <Component
        :is="component"
        v-for="({ component, id, props }, index) in teasers.slice(2, 4)"
        :key="id"
        v-bind="props"
        :fetchpriority="index < priorityFetchAmount ? 'high' : 'low'"
        @click="handleTeaserClick(id)"
      />

      <TrustTeaserCarousel class="carousel" :slides="trustTeaserAppReviews">
        <template #headline>
          <h2 class="text-h5">
            {{ trustTeaserCarousels?.appReviewsTitle }}
          </h2>
        </template>
      </TrustTeaserCarousel>

      <Component
        :is="component"
        v-for="({ component, id, props }, index) in teasers.slice(4)"
        :key="id"
        v-bind="props"
        :fetchpriority="index < priorityFetchAmount ? 'high' : 'low'"
        @click="handleTeaserClick(id)"
      />
    </NuxtLayout>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { TrustTeaserCardAppReviewProps } from '~/features/TrustTeaser/TrustTeaserCardAppReview.vue'
import type { TrustTeaserCardWinnerProps } from '~/features/TrustTeaser/TrustTeaserCardWinner.vue'

definePageMeta({
  footer: 'full',
  header: 'full',
  layout: false,
  middleware: ['age-verification', 'self-excluded'],
  tracking: { name: 'Homepage' },
})

const { isDesktop } = useDevice()
const licensedTerritory = useLicensedTerritory({ raw: true })

const query = useTeasersQuery(
  { licensedTerritory },
  {
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 3_000_000,
  },
)

const { data: trustTeaserCarouselsData } =
  useContentfulContent().getTrustTeaserCarouselsCollection()

const subscription = useDrawDateChangesSubscription({ licensedTerritory })
subscription.onResult(() => query.refetch())

const priorityFetchAmount = computed(() => (isDesktop ? 6 : 3))

const teasers = computed(() => {
  const teasers = query.result.value?.teasers.teasers ?? []
  return mapToTeaserComponents(teasers, licensedTerritory, query.refetch)
})

const trustTeaserAppReviews = computed(() => {
  const items =
    trustTeaserCarousels.value?.appReviewsSlidesCollection?.items ?? []

  // TODO Remove fallbacks when contentful schema finally gets it’s shit together
  return items.filter(isTruthy).map<TrustTeaserCardAppReviewProps>((item) => ({
    date: item.date ?? '',
    rating: item.rating ?? 5,
    review: item.review ?? '',
    store: item.store ?? '',
    username: item.username ?? '',
  }))
})

const trustTeaserCarousels = computed(
  () => trustTeaserCarouselsData.value?.items[0] ?? null,
)

const trustTeaserWinners = computed(() => {
  const items = trustTeaserCarousels.value?.winnersSlidesCollection?.items ?? []

  // TODO Remove fallbacks when contentful schema finally gets it’s shit together
  return items
    .filter(isTruthy)
    .map<Omit<TrustTeaserCardWinnerProps, 'index'>>((item) => ({
      game: item.game ?? '',
      image: {
        alt: item.image?.description ?? '',
        src: item.image?.url ?? '',
      },
      state: item.state?.id ?? '',
      winningAmount: item.winningAmount ?? 0,
    }))
})

const { pushSelectListItemEvent, pushViewListEvent } = useTracking()
const { createGenericListItem, createTeaserListItems } = useTrackingData()
const { logTrackingError } = useTrackingErrorLog()

const handleTeaserClick = (id: string) => {
  if (!query.result.value) return

  const item = query.result.value.teasers.teasers.find((teaser) => {
    const comparisonValue =
      teaser.__typename === 'LotteryTeaserPayload' ? teaser.id : teaser.slug

    return comparisonValue === id
  })

  if (!item) return

  try {
    pushSelectListItemEvent({
      items: createTeaserListItems([item]),
      list_id: TRACKING_TEASERS_LIST_ID,
      list_name: TRACKING_TEASERS_LIST_NAME,
    })
  } catch (err) {
    logTrackingError('select_list_item', err)
  }
}

const TRACKING_TEASERS_LIST_NAME = 'Homepage Items (Teasers)'
const TRACKING_TEASERS_LIST_ID = 'home_items_teasers_list'
let unwatchTeasers: (() => void) | null = null
unwatchTeasers = watchImmediate(query.result, (list) => {
  if (!list?.teasers || list.teasers.teasers.length === 0) return

  try {
    pushViewListEvent({
      items: createTeaserListItems(list.teasers.teasers),
      list_id: TRACKING_TEASERS_LIST_ID,
      list_name: TRACKING_TEASERS_LIST_NAME,
    })

    if (unwatchTeasers) unwatchTeasers()
  } catch (err) {
    logTrackingError('view_list', err)
  }
})

const TRACKING_WINNERS_LIST_NAME = 'Homepage Items (Winners)'
const TRACKING_WINNERS_LIST_ID = 'home_items_winners_list'
let unwatchWinners: (() => void) | null = null
unwatchWinners = watchImmediate(trustTeaserWinners, (list) => {
  if (!list || list.length === 0) return

  try {
    pushViewListEvent({
      items: createGenericListItem({
        itemCategory: TRACKING_WINNERS_LIST_ID,
        list: list.map((item) => ({
          ...item,
          name: item.game,
        })),
      }),
      list_id: TRACKING_WINNERS_LIST_ID,
      list_name: TRACKING_WINNERS_LIST_NAME,
    })

    if (unwatchWinners) unwatchWinners()
  } catch (err) {
    logTrackingError('view_list', err)
  }
})

const TRACKING_TRUST_LIST_NAME = 'Homepage Items (Trust)'
const TRACKING_TRUST_LIST_ID = 'home_items_trust_list'
let unwatchWTrustTeasers: (() => void) | null = null
unwatchWTrustTeasers = watchImmediate(trustTeaserAppReviews, (list) => {
  if (!list || list.length === 0) return

  try {
    pushViewListEvent({
      items: createGenericListItem({
        itemCategory: TRACKING_TRUST_LIST_ID,
        list: list.map((item) => ({ ...item, name: item.username })),
      }),
      list_id: TRACKING_TRUST_LIST_ID,
      list_name: TRACKING_TRUST_LIST_NAME,
    })

    if (unwatchWTrustTeasers) unwatchWTrustTeasers()
  } catch (err) {
    logTrackingError('view_list', err)
  }
})
</script>

<style lang="scss" scoped>
.carousel {
  margin: 0.75rem -0.5rem 1rem;

  @include media-desktop {
    grid-column: span 2;
    margin: -0.5rem -0.5rem 0.5rem;
  }
}
</style>
