import { ApolloError } from '@apollo/client/errors'

import type { DatadogPayload } from '~/composables/datadog'

import Money from '~/models/Money'

export const useRedeemMoney = () => {
  const auth = useAuth()
  const route = useRoute()
  const isClient = useIsClient()

  const { log } = useDatadog()
  const { addToast } = useToaster()
  const { mutate } = useRedeemMoneyMutation()
  const { t } = useI18n()

  const redeemMoney = async () => {
    let amount: Maybe<Money> = null

    if (!isClient || !route.query.redeem) return amount

    const code = String(route.query.redeem)

    const commonLogMessage: Omit<DatadogPayload, 'message'> = {
      appendPlayerInfo: true,
      component: 'useRedeemMoney',
      messageContext: { code },
    }

    const logRedeem = (
      state: 'failed' | 'started' | 'succeeded',
      error?: unknown,
    ) => {
      let message = `Redeem money ${state} for code ${code}`
      if (error) message += ` with error ${error}`
      log(error ? 'error' : 'info', {
        ...commonLogMessage,
        error,
        message,
      })
    }

    try {
      if (!auth.isLoggedIn.value) {
        navigateTo(
          createRedirectLocation({ path: '/login' }, useRoute().fullPath),
        )
        return
      }

      logRedeem('started')

      const result = await mutate({
        input: { code },
      })
      const price = result?.data?.requestMoneyBack.price
      if (price) amount = new Money(price)

      logRedeem('succeeded')
    } catch (error) {
      if (isClient && error instanceof ApolloError) {
        addToast({
          autoClose: false,
          headline: t('redeemmoney.toast.headline'),
          message: {
            translationKey: 'redeemmoney.toast.message',
            translationVariables: {
              cta: {
                text: t('redeemmoney.toast.cta'),
                to: '/account/transactions',
                type: 'link',
              },
            },
          },
          tracking: {
            topicKey: 'redeem_money_failed',
          },
        })
      }

      logRedeem('failed', error)
    }

    return amount
  }

  return { redeemMoney }
}
