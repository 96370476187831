<template>
  <article
    :aria-label="$t('trustteasercarousel.winnercard.arialabel')"
    class="trust-teaser-card-winner flex max-w-full rounded-md mr-xs"
    :class="color"
  >
    <aside class="flex flex-col justify-between p-xs text-white">
      <span class="text-h5">{{ amount }}</span>

      <div class="flex flex-col w-full">
        <span>{{ $t(`licensedterritory.${state}`) }}</span>
        <span class="game font-medium text-uppercase">
          {{ game }}
        </span>
      </div>
    </aside>

    <img
      v-bind="getImageSources(image.src, 154, 140)"
      :alt="image.alt"
      height="140"
      width="154"
    />
  </article>
</template>

<script lang="ts" setup>
import Money from '~/models/Money'

export type TrustTeaserCardWinnerProps = {
  game: string
  image: {
    alt: string
    src: string
  }
  index: number
  state: string
  winningAmount: number
}

const props = defineProps<TrustTeaserCardWinnerProps>()

const amount = computed(() =>
  formatMoney(new Money(props.winningAmount), { fractionDigits: 0 }),
)

const colorList = ['blue', 'orange', 'red', 'green']

const color = computed(() => colorList[props.index % colorList.length])
</script>

<style lang="scss" scoped>
.trust-teaser-card-winner {
  position: relative;
  overflow: hidden;
  min-width: 17rem;
  max-width: 17rem;
  height: 8.75rem;
  background-color: token('color.lightblue.500');

  aside {
    position: relative;
    width: 8.25rem;

    > * {
      z-index: 3;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
    }

    &::before {
      left: 0;
      z-index: 2;
      width: 8.25rem;
      background: url('./assets/dollars-bg.svg') top left no-repeat;
      clip-path: path('M0 0H132L127 140H0V0Z');
    }

    &::after {
      right: 0.25rem;
      z-index: 1;
      width: 2rem;
      box-shadow: 0.3125rem 0 0.75rem rgba(#000, 12%);
      transform: rotate(2deg);
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .game {
    @include truncate(3);

    line-height: $line-height-sm;
  }

  &.blue {
    aside::before {
      background-color: #278bdd;
    }
  }

  &.orange {
    aside::before {
      background-color: #df9000;
    }
  }

  &.red {
    aside::before {
      background-color: #e0263c;
    }
  }

  &.green {
    aside::before {
      background-color: #04b957;
    }
  }
}
</style>
