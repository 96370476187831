<template>
  <section v-if="slides.length" class="trust-teaser-carousel">
    <header class="flex items-center justify-between px-xs mb-xs">
      <slot name="headline" />

      <nav class="flex gap-xs">
        <LcButton
          :aria-label="$t('trustteasercarousel.button.prev')"
          icon="chevron-left"
          size="small"
          variant="filled"
          @click="carouselRef?.prev"
        />

        <LcButton
          :aria-label="$t('trustteasercarousel.button.next')"
          icon="chevron-right"
          size="small"
          variant="filled"
          @click="carouselRef?.next"
        />
      </nav>
    </header>

    <div class="carousel-wrap">
      <LcCarousel ref="carouselRef" class="carousel" :slides="preparedSlides">
        <template #slide="{ slide, index }">
          <Component
            :is="getSlideComponent(slide)"
            v-bind="slide"
            :key="index"
            :index="index"
          />
        </template>
      </LcCarousel>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { ComponentExposed } from 'vue-component-type-helpers'

import { TrustTeaserCardAppReview, TrustTeaserCardWinner } from '#components'
import { LcCarousel } from '@lottocom/frontend-components'

import type { TrustTeaserCardAppReviewProps } from '~/features/TrustTeaser/TrustTeaserCardAppReview.vue'
import type { TrustTeaserCardWinnerProps } from '~/features/TrustTeaser/TrustTeaserCardWinner.vue'

type TrustTeaserCarouselProps = {
  slides: TrustTeaserCarouselSlide[]
}

type TrustTeaserCarouselSlide =
  | Omit<TrustTeaserCardWinnerProps, 'index'>
  | TrustTeaserCardAppReviewProps

const props = defineProps<TrustTeaserCarouselProps>()

const carouselRef = ref<ComponentExposed<typeof LcCarousel>>()

const preparedSlides = computed(() => {
  // Fill array with duplicates to avoid empty slides in loop
  if (props.slides.length < 4) {
    return Array.from(
      { length: 4 },
      (_, index) => props.slides[index % props.slides.length],
    )
  }

  return props.slides
})

const getSlideComponent = (slide: unknown) => {
  if (isAppReview(slide)) return TrustTeaserCardAppReview
  return TrustTeaserCardWinner
}

const isAppReview = (slide: unknown): slide is TrustTeaserCardAppReviewProps =>
  typeof (slide as TrustTeaserCardAppReviewProps).rating === 'number'
</script>

<style lang="scss" scoped>
.trust-teaser-carousel {
  overflow: hidden;

  nav {
    display: none;
    padding-top: 0.5rem;

    @include media-desktop {
      display: flex;
    }
  }

  .carousel {
    overflow: visible;

    &-wrap {
      padding: 0 0.5rem;
    }

    @include media-desktop {
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        width: 4rem;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(#ecf1ff, 0%) 5%,
          rgba(#ecf1ff, 100%) 90%
        );
      }
    }
  }
}
</style>
