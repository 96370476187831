<template>
  <section v-if="!isLoggedIn" :class="[$style.root, $style.red]">
    <div :class="$style.blue">
      <div :class="$style.yellow">
        <div :class="$style.darkBlue">
          <h1 :class="$style.title">{{ $t('component.winevercard.title') }}</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
const { isLoggedIn } = useAuth()
</script>

<style lang="scss" module>
.root {
  margin: -1 * spacing('2xs') 0 spacing('xs');

  @include media-desktop {
    margin-bottom: spacing('md');
  }
}

.red {
  padding: spacing('xs') spacing('md');
  border-radius: spacing('xs');
  background: color('danger500');

  @include media-desktop {
    margin-bottom: spacing('md');
  }
}

.blue {
  max-width: 500px;
  margin: 0 auto;
  padding: spacing('xs') spacing('md');
  border-radius: spacing('2xs');
  background: color('info500');
}

.yellow {
  max-width: 356px;
  margin: 0 auto;
  padding: spacing('xs') spacing('md');
  border-radius: spacing('2xs');
  background: color('warning500');
}

.dark-blue {
  max-width: 276px;
  margin: 0 auto;
  padding: spacing('2xs') spacing('md');
  border-radius: spacing('2xs');
  background-color: color('secondary900');
}

.title {
  width: 100%;
  max-width: 232px;
  height: 26px;
  margin: 0 auto;
  background: url('./assets/winever-text.svg') center no-repeat;
  background-size: contain;
  text-indent: -9999px;
}
</style>
